@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .pill {
    transition: background-color 0.5s ease;
  }

  .pill.checked {
    @apply bg-base-white border-2 border-interactive-backgroundStrong hover:border-interactive-backgroundStrongHover;
  }
  .pill.checked.tab {
    @apply bg-base-white border-2 border-interactive-backgroundStrongOrange;
  }

  .pill.checked:hover .radio-pill {
    @apply bg-interactive-backgroundStrongHover;
  }

  .pill.checked.disabled {
    @apply bg-base-white;
  }

  .radio-pill.unchecked {
    @apply border-2 border-neutral-textStrong;
  }

  .radio-pill.checked {
    @apply bg-interactive-backgroundStrong flex items-center justify-center;
  }

  .radio-pill.unchecked.disabled {
    @apply border-neutral-textDisabled;
  }

  .radio-pill.checked.disabled {
    @apply bg-neutral-textDisabled;
  }

  .radio-pill.unchecked.has-icon {
    @apply border-0;
  }
}
