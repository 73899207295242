@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .arrow-up {
    @apply absolute bottom-full w-0 h-0 border-solid border-x-transparent border-b-base-platinum700;
    border-left-width: 12px;
    border-right-width: 12px;
    border-bottom-width: 12px;
  }

  .arrow-down {
    @apply absolute top-full w-0 h-0 border-solid border-x-transparent border-t-base-platinum700;
    border-left-width: 12px;
    border-right-width: 12px;
    border-top-width: 12px;
  }

  .arrow-right {
    @apply absolute left-full w-0 h-0 border-solid border-x-transparent border-l-base-platinum700;
    border-top-width: 12px;
    border-bottom-width: 12px;
    border-left-width: 12px;
  }

  .arrow-left {
    @apply absolute right-full w-0 h-0 border-solid border-x-transparent border-r-base-platinum700;
    border-top-width: 12px;
    border-bottom-width: 12px;
    border-right-width: 12px;
  }
}
